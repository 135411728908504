import { createRouter, createWebHistory } from 'vue-router'

import { Trans } from '../js/translation'

const Home = () => import('../views/Home.vue')
const Samples = () => import('../views/Samples.vue')
const Services = () => import('../views/Services.vue')
const Contact = () => import('../views/Contact.vue')
const Privacy = () => import('../views/Privacy.vue')
const LegalNotice = () => import('../views/LegalNotice.vue')

const routes = [
  {
    path: "/",
    component: {
      template: "<router-view></router-view>"
    },
    redirect: { name: 'Home', params: { lang: Trans.currentLanguage } }
  },
  {
    path: '/:lang/:pathMatch(.*)*',
    redirect: { name: 'Home', params: { lang: Trans.currentLanguage } }
  },
  {
    path: '/:lang',
    name: 'Lang',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        props: true
      },
      {
        path: 'services',
        name: 'Services',
        component: Services
      },
      {
        path: 'samples',
        name: 'Samples',
        component: Samples
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path: 'legalnotice',
        name: 'LegalNotice',
        component: LegalNotice
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  var btn = document.getElementById("btnDismissModal");
  if(btn){
    btn.click();
  }
  next();
});

export default router
