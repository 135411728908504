import { createI18n } from 'vue-i18n'

function getBrowserLocale(options = {}) {

  // Return locale set in url if de or en
  const pathLocale = window.location.pathname.split('/')[1];

  if (pathLocale == "de" || pathLocale == "en") {
    return pathLocale;
  }

  const defaultOptions = { countryCodeOnly: false }

  const opt = { ...defaultOptions, ...options }

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()

  return trimmedLocale
}

function supportedLocalesInclude(locale) {
  return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.includes(locale)
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en"
  }
}

export const startingLocale = getStartingLocale()

export const i18n = createI18n({
  locale: startingLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: { }
})
