import { createApp } from 'vue';
import App from './App.vue';

import router from './router';


const scss = () => import('./scss/custom.scss');
const bootstrap_carousel = () => import('bootstrap/js/dist/carousel');
const bootstrap_collapse = () => import('bootstrap/js/dist/collapse');

import {i18n} from './i18n';

import VueGtag from 'vue-gtag';
import { VueCookieNext } from 'vue-cookie-next';

const app = createApp(App);
app.use(router);
app.use(scss);
app.use(bootstrap_carousel);
app.use(bootstrap_collapse);
app.use(i18n);
app.use(VueCookieNext);
app.use(VueGtag, {
  config: { id: 'G-4W9EE0S7W2' },
  router,
  enabled: false,
});

app.provide('gtag', app.config.globalProperties.$gtag);

app.mount('#app');
