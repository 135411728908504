// Content functions.

/**
 * Gets the content from cockpit api.
 */
export async function getContent(model, locale) {
    try {
        var endpoint = "https://dengge.de/cockpit-core/api/content/item/" + model + "?locale=" + locale;

        var request = new Request(endpoint, {
            method: "GET",
            headers: {
                "api-key": process.env.VUE_APP_COCKPIT_APIKEY,
                "Content-Type": "application/json"
            }
        });

        const json = await fetch(request).then(response => response.json());
        return json;
    } catch {
        return {};
    }
}