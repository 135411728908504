import { i18n } from '../i18n'


// Translation functions.
export const Trans = {

  // Getter for the default language.
  get defaultLanguage () {
    return process.env.VUE_APP_I18N_LOCALE
  },
  
  // Getter for the supported languages.
  get supportedLanguages () {
    return process.env.VUE_APP_I18N_SUPPORTED_LOCALE
  },
  // Getter for the current language set.
  get currentLanguage () {
    return i18n.global.locale
  },
  // Setter for the current language.
  set currentLanguage (lang) {
    i18n.global.locale = lang
  },

  // Gets the first supported language that matches the user's
  getUserSupportedLang () {
    const userPreferredLang = Trans.getUserLang()

    // Check if user preferred browser lang is supported
    if (Trans.isLangSupported(userPreferredLang.lang)) {
      return userPreferredLang.lang
    }
    // Check if user preferred lang without the ISO is supported
    if (Trans.isLangSupported(userPreferredLang.langNoISO)) {
      return userPreferredLang.langNoISO
    }
    return Trans.defaultLanguage
  },

  // Returns the users preferred language
  getUserLang () {
    const lang = window.navigator.language || window.navigator.userLanguage || Trans.defaultLanguage
    return {
      lang: lang,
      langNoISO: lang.split('-')[0]
    }
  },

  // Sets the language to various services (axios, the html tag etc)
  setI18nLanguageInServices (lang) {
    Trans.currentLanguage = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
  },
 
  // Loads new translation messages and changes the language when finished
  async changeLanguage (lang) {
    if (!Trans.isLangSupported(lang)) return Promise.reject(new Error('Language not supported'))
    if (i18n.locale === lang) return Promise.resolve(lang) // has been loaded prior
    // the following 2 lines are needed if locale files are used
    //const msgs = await Trans.loadLanguageFile(lang)
    //i18n.global.setLocaleMessage(lang, msgs.default || msgs)
    return Trans.setI18nLanguageInServices(lang)
  },
  
  // Loads a translation file
  // loadLanguageFile (lang) {
  //   return import(`../locales/${lang}.json`)
  // },

  // Checks if a lang is supported
  isLangSupported (lang) {
    return Trans.supportedLanguages.includes(lang)
  },

  //Checks if the route's param is supported, if not, redirects to the first supported one.
  routeMiddleware (to, from, next) {
    // Load async message files.
    const lang = to.params.lang
    if (!Trans.isLangSupported(lang)) return next(Trans.getUserSupportedLang())
    return Trans.changeLanguage(lang).then(() => next())
  }
}