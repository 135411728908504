<template>
  <div>
    <nav id="pageNavbar" class="navbar navbar-expand-md fixed-top bg-gradient shadow-sm p-0">
      <div class="container-md">
        <router-link :to="'/' + $i18n.locale" class="navbar-brand">
          <picture>
            <img src="./assets/logo_single.webp" alt="Dengge" width="60" height="60" class="d-inline-block" />
          </picture>
        </router-link>
        <button id="btnNavbarToggler" class="navbar-toggler btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <img src="./assets/bootstrap_icons/list.svg" alt="" width="32" height="32" />
        </button>
        <div class="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-2 mb-md-0 gap-2">
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale" exact-active-class="custom-active"
                class="btn fw-bold w-100 text-end text-md-center">{{ contentNavigation["Home"] }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/services'" exact-active-class="custom-active"
                class="btn fw-bold w-100 text-end text-md-center">{{ contentNavigation["Services"] }}</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link
                :to="'/' + $i18n.locale + '/samples'"
                exact-active-class="custom-active"
                class="btn fw-bold w-100 text-start"
                >{{ contentNavigation["Samples"] }}</router-link
              >
            </li> -->
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/contact'" exact-active-class="custom-active"
                class="btn fw-bold w-100 text-end text-md-center">{{ contentNavigation["Contact"] }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <router-view class="navbar-padding" :contentNavigation="contentNavigation" :contentGeneral="contentGeneral" />
    <footer class="fixed-sides custom-shadow p-3 mt-3">
      <div class="container">
        <div class="row">
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush mt-1">
              <li class="list-group-item border-0 p-0">
                <b>{{ contentFooter["AboutUs"] }}</b>
              </li>
              <li class="list-group-item border-0 p-0">
                <router-link :to="'/' + $i18n.locale + '/privacy'" class="p-0">{{ contentNavigation["Privacy"] }}
                </router-link>
              </li>
              <li class="list-group-item border-0 p-0">
                <router-link :to="'/' + $i18n.locale + '/legalnotice'" class="p-0">{{ contentNavigation["LegalNotice"] }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush mt-1">
              <li class="list-group-item border-0 p-0">
                <b>{{ contentFooter["Languages"] }}</b>
              </li>
              <li class="list-group-item border-0 p-0">
                <span @click="changeLanguage('de')" class="custom-link p-0">Deutsch</span>
              </li>
              <li class="list-group-item border-0 p-0">
                <span @click="changeLanguage('en')" class="custom-link p-0">English</span>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 mb-1">
            <ul class="list-group list-group-flush mt-1">
              <li class="list-group-item border-0 p-0">
                <b>{{ contentFooter["Cookies"] }}</b>
              </li>
              <li class="list-group-item border-0 p-0">
                <span @click="showCookieBanner(true)" class="custom-link p-0">Cookies</span>
              </li>
            </ul>
          </div>
          <div class="col-sm-4">
            <!-- <b>{{ contentFooter["Social") }}</b>
            <div class="row">
              <a
                aria-label="Twitter"
                href="https://twitter.com/DenggeUG"
                class="col-1 text-center"
              >
          <img src="./assets/bootstrap_icons/twitter.svg" aria-hidden="true" width="32" height="32" /></a>
              <a
                aria-label="Instagram"
                href="https://www.instagram.com/denggeug/"
                class="col-1 text-center text-dark"
              >
          <img src="./assets/bootstrap_icons/list.svg" aria-hidden="true" width="32" height="32" /></a>
            </div> -->
          </div>
        </div>
      </div>
    </footer>
    <div id="cookieBanner" class="custom-cookie-consent rounded-2 p-5 custom-margin shadow-lg"
      v-if="showBanner | showBannerManual">
      <div class="">
        <h1>{{ contentCookies["Title"] }}</h1>
        <p>
          {{ contentCookies["Text"] }}
        </p>
        <p>
          {{ contentCookies["MoreInformation"][0] }}
          <router-link :to="'/' + $i18n.locale + '/privacy'">
            {{ contentCookies["MoreInformation"][1] }}
          </router-link>.
        </p>
        <b>
          {{ contentCookies["UsedCookies"] }}
        </b>
        <div class="ps-4 pb-4">
          <b>
            {{ contentCookies["Types-Statistics-Title"] }}
          </b>
          <p class="custom-link ps-4" data-bs-toggle="modal" data-bs-target="#googleAnalyticsModal">
            {{ contentCookies["Types-Statistics-Values"][0] }}</p>
        </div>
        <hr />
        <div class="d-flex flex-column flex-sm-row justify-content-end gap-1">
          <button class="btn btn-secondary fw-bold rounded-pill border-2 mx-sm-3 px-sm-5" @click="
            declineBtnClicked();
          showCookieBanner(false);
          ">
            {{ contentCookies["Buttons-Decline"] }}
          </button>
          <button class="btn btn-primary fw-bold rounded-pill border-2 mx-sm-3 px-sm-5" @click="
            okBtnClicked();
          showCookieBanner(false);
          ">
            {{ contentCookies["Buttons-Accept"] }}
          </button>
        </div>
      </div>
    </div>
    <div class="modal modal-lg" id="googleAnalyticsModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ contentCookies["GoogleAnalytics-Titles"][0] }}</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][0] }}
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][1] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][1] }}<br>
              {{ contentCookies["GoogleAnalytics-Texts"][2] }}
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][2] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][3] }}
            </p>
            <ul>
              <li>{{ contentCookies["GoogleAnalytics-Texts"][4] }}</li>
            </ul>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][3] }}
            </h5>
            <ul>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][5] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][6] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][7] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][8] }}
              </li>
            </ul>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][4] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][9] }}
            </p>
            <ul>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][10] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][11] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][12] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][13] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][14] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][15] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][16] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][17] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][18] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][19] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][20] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][21] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][22] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][23] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][24] }}
              </li>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][25] }}
              </li>
            </ul>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][5] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][26] }}
            </p>
            <ul>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][27] }}
              </li>
            </ul>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][6] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][28] }}
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][7] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][29] }}
            </p>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][30] }}
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][8] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][31] }}
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][9] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][32] }}
            </p>
            <p>
              <a href="https://support.google.com/policies/contact/general_privacy_form">
                {{ contentCookies["GoogleAnalytics-Texts"][33] }}
              </a>
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][10] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][34] }}
            </p>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][35] }}
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][11] }}
            </h5>
            <p>
              <a href="https://policies.google.com/privacy?hl=en">
                {{ contentCookies["GoogleAnalytics-Texts"][36] }}
              </a>
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][12] }}
            </h5>
            <p>
              <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                {{ contentCookies["GoogleAnalytics-Texts"][37] }}
              </a>
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][13] }}
            </h5>
            <p>
              <a href="https://policies.google.com/technologies/cookies?hl=en">
                {{ contentCookies["GoogleAnalytics-Texts"][38] }}
              </a>
            </p>
            <h5>
              {{ contentCookies["GoogleAnalytics-Titles"][14] }}
            </h5>
            <p>
              {{ contentCookies["GoogleAnalytics-Texts"][39] }}
            </p>
            <ul>
              <li>
                {{ contentCookies["GoogleAnalytics-Texts"][40] }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import useCookies from "./js/cookies";
import { getContent } from "./js/content";

export default {
  name: "App",
  async created() {
    const contentGeneral = await getContent("General", this.$i18n.locale);
    const contentNavigation = await getContent("Navigation", this.$i18n.locale);
    const contentFooter = await getContent("Footer", this.$i18n.locale);
    const contentCookies = await getContent("Cookies", this.$i18n.locale);

    this.contentGeneral = contentGeneral;
    this.contentNavigation = contentNavigation;
    this.contentFooter = contentFooter;
    this.contentCookies = contentCookies;
  },
  mounted() {
    var navLinks = document.querySelectorAll(".nav-item");
    navLinks.forEach((l) => {
      l.addEventListener("click", () => {
        document.getElementById("btnNavbarToggler").click();
      });
    });
  },
  methods: {
    changeLanguage(lang) {
      const to = this.$router.resolve({ params: { lang } });
      window.location.href = to.path;
    },
    showCookieBanner(bool) {
      this.showBannerManual = bool;
    },
  },
  setup() {
    const gtag = inject("gtag");
    const { okClicked, declineClicked, showBanner } = useCookies(gtag);
    const okBtnClicked = () => okClicked();
    const declineBtnClicked = () => declineClicked();

    return {
      showBanner,
      okBtnClicked,
      declineBtnClicked,
    };
  },
  data() {
    return {
      showBannerManual: false,
      contentGeneral: {},
      contentNavigation: {},
      contentFooter: {},
      contentCookies: {}
    };
  },
};
</script>
<style scoped>
.navbar-padding {
  padding-top: 4.3rem;
}

.fixed-sides {
  right: 0;
  left: 0;
}

.custom-active {
  text-decoration: underline;
}

.custom-shadow {
  box-shadow: 0 -0.3rem 0.3rem rgb(0 0 0 / 18%);
}
</style>